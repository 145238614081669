import { InjectedConnector } from '@web3-react/injected-connector'
import { ConnectorNames } from 'constants/types'
import Web3 from 'web3'
import getAvailablePublicChains from './getAvailablePublicChains'

const chains = getAvailablePublicChains()

export const chainIds = Array.from(
  new Set([
    56,
    97,
    42161,
    165,
    2020,
    2021,
    421611,
    1516,
    80001,
    84531,
    84532,
    500,
    137,
    5,
    ...(chains?.map((chain) => chain.chainID) || []),
  ]),
)

const injected = new InjectedConnector({ supportedChainIds: chainIds })

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
}

export const getLibrary = (provider): Web3 => {
  return provider
}
